export const enStrings = {
    accountsBot: "Accounts",
    accountsActual: "Accounts (actual)",
    analytics: "Analytics",
    artist: "Artist",
    by: "By: ",
    country: "Country",
    days: "days",
    filter: "Filter",
    filterBy: "Filter by...",
    hideFilters: "Hide filters",
    hours: "hours",
    h:"h",
    infoAnalytics: "The actual streams are synchronized daily, so these reports may not reflect your figures at the exact point of time.",
    login: "LOGIN",
    logout: "Log out",
    minutes: "minutes",
    m:"m",
    ok: "OK",
    period: "Period: ",
    streamsBot: "Streams (bot)",
    streamsActual: "Streams (actual)",
    seconds: "seconds",
    s:"s",
    sBotsAnalytics: "STREAMING BOTS - Analytics",
    startTyping: "Start typing",
    service: "Service",
    totalAccounts: "Total accounts",
    totalStreams: "Total streams",
    totalUniqueListeners: "Total unique listens",
    uniqueListenersBot: "Unique listens (bot)",
    uniqueListenersActual: "Unique listens (actual)",
    uniqueListeners: "Unique listens",
    uniqueListensExplanation: "* Unique listens = number of unique track plays by accounts in the time period. If an account plays the same track more times in the time period, it still counts as one unique listen.",
    view: "View: ",
    welcomeAnalytics: "Here you will find detailed stream statistics. ",
    serviceFilter: "Service:",
    summaryReport: "Summary report",
    dashboard: "Dashboard",
    dateFrom: "Date from:",
    dateTo: "Date to:",
    date: "Date: ",
    startDate: "Start date",
    accountNumber: "Number of accounts",
    accountGroupName: "Account group name",
    accountGroupId: "Account group ID",
    dateCreated: "Date created",
    byServiceHeader: "Summary Report By Service Type",
    byAccountGroupHeader: "Summary Report For Account Groups",
    areaPlotHeader: "Summary Report By Service Type - Area Plot",
    barChartHeader: "Summary Report For Account Groups - Bar Chart",
    ours: "Label Artists",
    notOurs: "Others",
    total: "Total"
};
